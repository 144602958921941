import {
  useFetcher,
  useLoaderData,
  useLocation,
  useNavigation,
} from '@remix-run/react';
import { useEffect, useState } from 'react';
import logger from '~/services/logger';
import {
  getIsRevalidateLoginStateFromRedirect,
  getVersionedIsLoggedInKey,
  IS_LOGGED_IN_KEY,
  isLoggedInClientState,
} from '~/utils/is-logged-in-client-state';

/**
 * This hook sets the `isLoggedIn` value in client storage.
 * It calls the `/api/is-logged-in` endpoint to get the `isLoggedIn` value
 * only if the value is not already set in client storage.
 */
export function useLoadIsLoggedIn() {
  const fetcher = useFetcher<{ isLoggedIn: boolean }>({
    key: IS_LOGGED_IN_KEY,
  });

  const location = useLocation();

  const { isLoggedIn } = useIsLoggedIn();

  const removeIsLoggedIn = async () => {
    await isLoggedInClientState().remove();
  };

  useEffect(() => {
    const callLoginApi: boolean =
      !isLoggedIn && fetcher.state === 'idle' && !fetcher.data;

    if (!callLoginApi) return;

    if (getIsRevalidateLoginStateFromRedirect()) removeIsLoggedIn();

    fetcher.submit({}, { action: '/api/is-logged-in', method: 'post' });
  }, [fetcher.state, location.pathname, isLoggedIn]);
}

export function useIsLoggedIn(): {
  isLoggedIn: boolean | null;
  preventRenderByIsLoggedIn: boolean;
} {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const [preventRenderByIsLoggedIn, setPreventRenderByIsLoggedIn] =
    useState<boolean>(true);

  const locationPathname: string = useLocation().pathname.toLowerCase();

  const preventRenderByRouteAndLoggedIn: boolean =
    !isLoggedIn &&
    (locationPathname === '/login' || locationPathname === '/payment');

  const fetcher = useFetcher<{ isLoggedIn: boolean }>({
    key: IS_LOGGED_IN_KEY,
  });

  const navigation = useNavigation();

  const getAndSetIsLoggedIn = async () => {
    const isLoggedInValue: boolean | null = await isLoggedInClientState().get();
    setIsLoggedIn(isLoggedInValue);
  };

  useEffect(() => {
    getAndSetIsLoggedIn();
  }, [fetcher.state]);

  useEffect(() => {
    setPreventRenderByIsLoggedIn(
      (isLoggedIn === null && navigation.state !== 'loading') ||
        preventRenderByRouteAndLoggedIn
    );
  }, [navigation.state, isLoggedIn === null]);

  useSyncIsLoggedInAcrossTabs(setIsLoggedIn);

  return { isLoggedIn, preventRenderByIsLoggedIn };
}

function useSyncIsLoggedInAcrossTabs(
  setIsLoggedIn: (isLoggedIn: boolean | null) => void
) {
  const syncIsLoggedInAcrossTabs = (event: StorageEvent) => {
    if (event.key !== getVersionedIsLoggedInKey()) return;
    const newValue: string | null = event.newValue;
    if (newValue === null) return;
    setIsLoggedIn(newValue === 'true');
  };

  useEffect(() => {
    window.addEventListener('storage', syncIsLoggedInAcrossTabs);

    return () => {
      window.removeEventListener('storage', syncIsLoggedInAcrossTabs);
    };
  }, []);
}

export async function useMockIsLoggedIn(isMock: boolean): Promise<void> {
  useEffect(() => {
    if (!isMock) return;

    window.versionInfo = { APP_VERSION_UUID: 'mock' };

    (async () => {
      await isLoggedInClientState().set(true);
    })();
  }, []);
}

/**
 * Sets the `isLoggedIn` value in client storage.
 * Should be used only in routes w/ out caching.
 * This hook needs access to the `isLoggedIn` and `APP_VERSION_UUID` values
 * from the loader data.
 */
export function useSetIsLoggedInFromServerLoader(): void {
  const { isLoggedIn, APP_VERSION_UUID } = useLoaderData<any>();

  useEffect(() => {
    window.versionInfo = { APP_VERSION_UUID };

    if (!APP_VERSION_UUID || typeof isLoggedIn === 'undefined') {
      logger.error('APP_VERSION_UUID or isLoggedIn is undefined');
      return;
    }

    (async () => {
      await isLoggedInClientState().set(isLoggedIn);
    })();
  }, []);
}
